#root {
  width: 100%;
  /* max-height: 100vh; */
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #eeeae3;
  /* background-color: #FF7518; */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.react-horizontal-scrolling-menu--scroll-container {
  padding-top: 10px;
  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
  /* Hide scrollbar for WebKit/Blink */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}
